import React from "react"
import SiteLayout from "../components/SiteLayout"
import { graphql } from "gatsby"
import CategoriesWidget from "../components/CategoriesWidget"
import RecentPostsWidget from "../components/RecentPostsWidget"
import PostEntry from "../components/PostEntry"
import Seo from "../components/Seo"

const CategoryTemplate = props => {
  const {
    location,
    data: {
      allWpCategory
    },
  } = props
  const category = allWpCategory.edges[0].node
  const { name } = category

  return (
    <SiteLayout location={location}>
      <Seo title={`${name}`} />
      <h1>Category: {name}</h1>
      <hr />
      {/* {category.posts.nodes &&
        category.posts.nodes.map(post => <div key={post.id}><PostEntry post={post} /></div>)} */}
      <RecentPostsWidget />
      <CategoriesWidget />
    </SiteLayout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query GET_CATEGORY($id: String!) {
    allWpCategory(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          name
          slug
          posts {
            nodes {
              author {
                node {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      }
    }
  }
`
